<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <b-overlay :show="loading">
          <iq-card>
            <template v-slot:headerTitle>
              <h4 class="card-title">{{ $t('component_settings.service_precondition') }} {{ $t('globalTrans.add') }}</h4>
            </template>
            <template v-slot:body>
              <ValidationObserver ref="form"  v-slot="{ handleSubmit }">
                <b-form  @submit.prevent="handleSubmit(register)">
                  <b-row>
                    <b-col lg="6" sm="6" md="6">
                        <ValidationProvider name="Condition Type" vid="condition_type" rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="condition_type"
                                slot-scope="{ valid, errors }"
                            >
                                <template v-slot:label>
                                    {{$t('component_settings.condition_type')}}<span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                plain
                                v-model="formData.condition_type"
                                id="condition_type"
                                :options="conditionTypeList"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                </b-form-select>
                                    <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>
                  </b-row>
                  <b-row v-if="formData.condition_type === 'depend_other'">
                    <b-col lg="6" xl="6">
                      <ValidationProvider name="Organization" vid="org_id_from" rules="required|min_value:1">
                        <b-form-group
                          class="row"
                            label-cols-sm="4"
                            :label-for="`org_id_from`"
                            slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                              {{ $t('globalTrans.organization') }} ({{ $t('globalTrans.from') }}) <span class="text-danger">*</span>
                          </template>
                          <b-form-select
                            plain
                            v-model="formData.org_id_from"
                            :options="organizationList"
                            id="org_id_from"
                            :state="errors[0] ? false : (valid ? true : null)"
                            >
                            <template v-slot:first>
                              <b-form-select-option :value="0">{{$t('component_settings.select_organization')}}</b-form-select-option>
                            </template>
                          </b-form-select>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col lg="6" xl="6">
                      <ValidationProvider name="Organization" vid="org_id_to" rules="required|min_value:1">
                        <b-form-group
                          class="row"
                            label-cols-sm="4"
                            :label-for="`org_id_from`"
                            slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                              {{ $t('globalTrans.organization') }} ({{ $t('globalTrans.to') }})<span class="text-danger">*</span>
                          </template>
                          <b-form-select
                            plain
                            v-model="formData.org_id_to"
                            :options="organizationList"
                            id="org_id_to"
                            :state="errors[0] ? false : (valid ? true : null)"
                            >
                            <template v-slot:first>
                              <b-form-select-option :value="0">{{$t('component_settings.select_organization')}}</b-form-select-option>
                            </template>
                          </b-form-select>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col lg="6" xl="6">
                      <ValidationProvider name="Service Name" vid="service_id_from" rules="required|min_value:1">
                        <b-form-group
                          class="row"
                            label-cols-sm="4"
                            :label-for="`service_id_from`"
                            slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                              {{ $t('dropdown_settings.service') }} ({{ $t('globalTrans.from') }})<span class="text-danger">*</span>
                          </template>
                          <b-form-select
                            plain
                            v-model="formData.service_id_from"
                            :options="serviceListFrom"
                            id="org_id"
                            :state="errors[0] ? false : (valid ? true : null)"
                            >
                            <template v-slot:first>
                              <b-form-select-option :value="0" >{{$t('component_settings.select_service')}}</b-form-select-option>
                            </template>
                          </b-form-select>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col lg="6" xl="6">
                      <ValidationProvider name="Service Name" vid="service_id_to" rules="required|min_value:1">
                        <b-form-group
                          class="row"
                            label-cols-sm="4"
                            :label-for="`service_id_to`"
                            slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                              {{ $t('dropdown_settings.service') }} ({{ $t('globalTrans.to') }})<span class="text-danger">*</span>
                          </template>
                          <b-form-select
                            plain
                            v-model="formData.service_id_to"
                            :options="serviceListTo"
                            id="org_id"
                            :state="errors[0] ? false : (valid ? true : null)"
                            >
                            <template v-slot:first>
                              <b-form-select-option :value="0" >{{$t('component_settings.select_service')}}</b-form-select-option>
                            </template>
                          </b-form-select>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                     <b-col lg="6" sm="6">
                      <ValidationProvider name="Year" vid="year" rules="required">
                        <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="year"
                          slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                          {{ $t('globalTrans.year') }} <span class="text-danger">*</span>
                          </template>
                          <b-form-input
                            plain
                            v-model="formData.year"
                            id="org_id"
                            :state="errors[0] ? false : (valid ? true : null)"
                            >
                          </b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                  </b-row>
                  <b-row v-if="formData.condition_type === 'depend_other'">
                      <b-col lg="12" sm="12" md="12">
                        <h5>{{ $t('step_name.rules') }}</h5>
                      </b-col>
                      <b-col lg="10" sm="10" md="10">
                          <ValidationProvider name="Rules En" vid="rule_en">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="2"
                                  label-for="rule_en"
                              >
                                  <template v-slot:label>
                                      {{$t('component_settings.rule_input_en')}}
                                  </template>
                                  <b-form-input
                                  id="label"
                                  v-model="rule_item.text"
                                  :placeholder="$t('component_settings.rule_input_en')"
                                  ></b-form-input>
                              </b-form-group>
                          </ValidationProvider>
                          <ValidationProvider name="Rules Bn" vid="rule_bn">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="2"
                                  label-for="rule_bn"
                              >
                                  <template v-slot:label>
                                      {{$t('component_settings.rule_input_bn')}}
                                  </template>
                                  <b-form-input
                                  id="label"
                                  v-model="rule_item.text_bn"
                                  :placeholder="$t('component_settings.rule_input_bn')"
                                  ></b-form-input>
                              </b-form-group>
                          </ValidationProvider>
                      </b-col>
                      <b-col lg="2" sm="2" md="2">
                          <b-button @click="addItem" variant="primary"><i class="fa fa-plus"></i></b-button>
                      </b-col>
                  </b-row>
                  <b-row v-if="formData.condition_type === 'depend_other'">
                      <b-col>
                          <table class="table">
                              <tr v-for="(item, index) in formData.rules" :key="index">
                                  <td>
                                      <ValidationProvider name="Rule En" vid="rule_en">
                                          <b-form-group
                                              class="row"
                                              label-cols-sm="4"
                                              label-for="rule_en"
                                          >
                                              <template v-slot:label>
                                                  {{$t('component_settings.rule_input_en')}}
                                              </template>
                                              <b-form-input
                                                  id="label"
                                                  v-model="item.text"
                                                  :placeholder="$t('component_settings.rule_input_bn')"
                                              ></b-form-input>
                                          </b-form-group>
                                      </ValidationProvider>
                                      <ValidationProvider name="Rule Bn" vid="rule_bn">
                                          <b-form-group
                                              class="row"
                                              label-cols-sm="4"
                                              label-for="rule_bn"
                                          >
                                              <template v-slot:label>
                                                  {{$t('component_settings.rule_input_bn')}}
                                              </template>
                                              <b-form-input
                                                  id="label"
                                                  v-model="item.text_bn"
                                                  :placeholder="$t('component_settings.rule_input_bn')"
                                              ></b-form-input>
                                          </b-form-group>
                                      </ValidationProvider>
                                  </td>
                                  <td class="text-right">
                                      <button @click="deleteItem(index)" class="btn btn-danger btn-xs"><i class="fa fa-trash"></i></button>
                                  </td>
                              </tr>
                          </table>
                      </b-col>
                  </b-row>
                  <b-row v-if="formData.condition_type === 'notice'">
                    <b-col lg="6" xl="6">
                      <ValidationProvider name="Organization" vid="org_id_from" rules="required|min_value:1">
                        <b-form-group
                          class="row"
                            label-cols-sm="4"
                            :label-for="`org_id_from`"
                            slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                              {{ $t('globalTrans.organization') }} <span class="text-danger">*</span>
                          </template>
                          <b-form-select
                            plain
                            v-model="formData.org_id"
                            :options="organizationList"
                            id="org_id_from"
                            :state="errors[0] ? false : (valid ? true : null)"
                            >
                            <template v-slot:first>
                              <b-form-select-option :value="0">{{$t('component_settings.select_organization')}}</b-form-select-option>
                            </template>
                          </b-form-select>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col lg="6" xl="6">
                      <ValidationProvider name="Service Name" vid="service_id_from" rules="required|min_value:1">
                        <b-form-group
                          class="row"
                            label-cols-sm="4"
                            :label-for="`service_id_from`"
                            slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                              {{ $t('dropdown_settings.service') }} <span class="text-danger">*</span>
                          </template>
                          <b-form-select
                            plain
                            v-model="formData.service_id_from"
                            :options="serviceListTo"
                            id="org_id"
                            :state="errors[0] ? false : (valid ? true : null)"
                            >
                            <template v-slot:first>
                              <b-form-select-option :value="0" >{{$t('component_settings.select_service')}}</b-form-select-option>
                            </template>
                          </b-form-select>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col lg="6" xl="6">
                      <ValidationProvider name="Notice" vid="notice_id" rules="required|min_value:1">
                        <b-form-group
                          class="row"
                            label-cols-sm="4"
                            :label-for="`notice_id`"
                            slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                              {{ $t('component_settings.notice') }} <span class="text-danger">*</span>
                          </template>
                          <b-form-select
                            plain
                            v-model="formData.notice_id"
                            :options="noticeList"
                            id="notice_id"
                            :state="errors[0] ? false : (valid ? true : null)"
                            >
                            <template v-slot:first>
                              <b-form-select-option :value="0">{{$t('component_settings.select_notice')}}</b-form-select-option>
                            </template>
                          </b-form-select>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col lg="6" sm="12">
                        <ValidationProvider name="Start date" vid="start_date" rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="start_date"
                                slot-scope="{ valid, errors }"
                            >
                                <template v-slot:label>
                                    {{$t('globalTrans.start_date')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-input
                                    readonly
                                    id="date-picker"
                                    v-model="formData.start_date"
                                    placeholder="Select Date"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>
                    <b-col lg="6" sm="12">
                        <ValidationProvider name="End date" vid="end_date" rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="end_date"
                                slot-scope="{ valid, errors }"
                            >
                                <template v-slot:label>
                                    {{$t('globalTrans.end_date')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-input
                                    id="date-picker"
                                    readonly
                                    v-model="formData.end_date"
                                    placeholder="Select Date"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>
                  </b-row>
                  <div class="row">
                    <div class="col-sm-3"></div>
                    <div class="col text-right">
                      <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                    </div>
                  </div>
                </b-form>
              </ValidationObserver>
            </template>
          </iq-card>
        </b-overlay>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '../../../../../config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { licenseRegistrationServiceBaseUrl } from '../../../../../config/api_config'
import { preconditionStore, preconditionUpdate } from '../../api/routes'
import flatpickr from 'flatpickr'

export default {
  components: {
    ValidationObserver,
    ValidationProvider
  },
  data () {
    return {
      saveBtnName: this.$route.query.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      formData: {
        condition_type: 'depend_other',
        org_id: 0,
        org_id_from: 0,
        org_id_to: 0,
        service_id_from: 0,
        service_id_to: 0,
        year: '',
        rules: [],
        notice_id: 0,
        start_date: '',
        end_date: ''
      },
      rule_item: {
        text: '',
        text_bn: '',
        checked: false
      },
      serviceListFrom: [],
      serviceListTo: [],
      loading: false,
      preConditionTypeList: [
        { item: 'depend_other', text_en: 'Otehr Service Dependent', text_bn: 'অন্যান্য সেবা নির্ভর' },
        { item: 'notice', text_en: 'Notice', text_bn: 'নোটিস' }
      ],
      noticeList: []
    }
  },
  created () {
    if (this.$route.query.id) {
      const tmp = this.getEditData()
      this.formData = tmp
      this.formData.rules = JSON.parse(tmp.rules)
    }
  },
  mounted () {
    flatpickr('#date-picker', {})
    core.index()
  },
  computed: {
    organizationList: function () {
      return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
    },
    conditionTypeList: function () {
        return this.preConditionTypeList.map(item => {
            return Object.assign({}, item, { value: item.item, text: this.$i18n.locale === 'en' ? item.text_en : item.text_bn })
        })
    }
  },
  watch: {
    'formData.condition_type': function (newVal) {
    },
    'formData.org_id_from': function (newVal) {
      this.serviceListFrom = this.getServiceList(newVal)
    },
    'formData.org_id_to': function (newVal) {
      this.serviceListTo = this.getServiceList(newVal)
    },
    'formData.org_id': function (newVal) {
      this.serviceListTo = this.getServiceList(newVal)
    },
    'formData.service_id_from': function (newVal) {
      if (this.formData.condition_type === 'notice') {
        this.formData.service_id_to = newVal
        this.noticeList = this.$store.state.licenseRegistration.commonObj.noticeList.filter(item => item.service_id === parseInt(newVal))
      }
    },
    'formData.notice_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        const data = this.$store.state.licenseRegistration.commonObj.noticeList.find(item => item.value === newVal)
        this.formData.start_date = data.start_date
        this.formData.end_date = data.end_date
      }
    }
  },
  methods: {
    addItem () {
        const drpOption = JSON.parse(JSON.stringify(this.rule_item))
        this.formData.rules.push(drpOption)
        this.rule_item.text = ''
        this.rule_item.text_bn = ''
    },
    deleteItem (index) {
      this.formData.rules.splice(index, 1)
    },
    getEditData () {
      const tmpData = this.$store.state.list.find(item => item.id === parseInt(this.$route.query.id))
      return JSON.parse(JSON.stringify(tmpData))
    },
    async register () {
      this.loading = true
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadinState = { loading: false, listReload: false }
      if (this.$route.query.id) {
        result = await RestApi.putData(licenseRegistrationServiceBaseUrl, `${preconditionUpdate}/${this.$route.query.id}`, this.formData)
      } else {
        result = await RestApi.postData(licenseRegistrationServiceBaseUrl, preconditionStore, this.formData)
      }
      this.loading = false
      loadinState.listReload = true
      this.$store.dispatch('mutateCommonProperties', loadinState)
      if (result.success) {
        this.$store.dispatch('licenseRegistration/mutateLicenseRegistrationProperties', { hasDropdownLoaded: false })
        this.$toast.success({
          title: 'Success',
          message: this.$route.query.id ? 'Data updated successfully' : 'Data save successfully',
          color: '#D6E09B'
        })
        this.$router.go(-1)
      } else {
        this.$refs.form.setErrors(result.errors)
        this.$toast.error({
          title: 'Error',
          message: 'Operation failed! Please, try again.'
        })
      }
    },
    getServiceList (orgId) {
      return this.$store.state.licenseRegistration.commonObj.serviceNamesList.filter(item => item.status === 1 && item.org_id === orgId)
    }
  }
}
</script>
<style scoped>
  .form-style-2-heading{
    color: #3f414d;
    font-style: italic;
    border-bottom: 1px solid #3f414d;
    margin-bottom: 20px;
    font-size: 15px;
    padding-bottom: 3px;
  }
</style>
